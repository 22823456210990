import { useEffect, useState } from "react";
import axios from "axios";

const useBlogs = (blogId, countryId) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const response = await axios.get("/d.json");

      const blogs = response.data.documents
        .filter((blog) => !countryId || blog.CountryId === countryId)
        .sort((b1, b2) => (b1.CreationDate > b2.CreationDate ? 1 : -1));
      console.log(blogs);
      if (!blogId) {
        setBlogs(blogs.reverse());
      } else {
        setBlogs(blogs.filter((blog) => blog.Id === blogId));
      }
    };

    fetchBlogs();
  }, [blogId]);

  return {
    blogs,
  };
};

export default useBlogs;
