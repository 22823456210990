import useBlogs from "./useBlogs";
import { useSearchParams } from "react-router-dom";
import usePictures from "./usePictures";
import useCountries from "./useCountries";
import About from "./about";
import Photos from "./photos";
import { useState } from "react";

function Home() {
  const [openCountries, setOpenCountries] = useState(false);
  const [searchParams] = useSearchParams();
  const blogId = searchParams.get("blogId");
  const countryId = searchParams.get("country");
  const { blogs } = useBlogs(blogId, countryId);
  const { mainImages, photos } = usePictures(blogId);
  const { countries } = useCountries();

  if (searchParams.get("about")) return <About />;

  if (searchParams.get("photos") && blogId)
    return (
      <Photos
        photos={photos.filter(
          (photo) => photo.indexOf(`/Blog_${blogId}/`) > -1
        )}
      />
    );

  return (
    <>
      {!blogId && (
        <div
          style={{
            position: "fixed",
            right: 0,
            top: 100,
            textAlign: "end",
          }}
        >
          <button
            style={{
              padding: "0.5rem  0.75rem",
              textTransform: "uppercase",
              fontSize: "0.75rem",
              fontWeight: 500,
              letterSpacing: "0.25rem",
              top: 100,
              right: 0,
              border: "1px solid rgba(0, 0, 0, 0.08)",
              backgroundColor: "#000",
              color: "#FFF",
              cursor: "pointer",
            }}
            onClick={() => setOpenCountries(!openCountries)}
          >
            Countries
          </button>
          {openCountries && (
            <ul>
              {[{ Id: undefined, Name: "All" }, ...countries].map((country) => (
                <li
                  key={country.Id}
                  style={{
                    height: "1.75rem",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.9rem",
                  }}
                >
                  <a
                    style={{
                      backgroundColor:
                        countryId === country.Id ? "#626262" : "#F5F5F5",
                      color: countryId === country.Id ? "#F5F5F5" : "#626262",
                      flex: 1,
                      padding: "0.5rem 1.5rem",
                    }}
                    href={
                      country.Id === countryId || !country.Id
                        ? "/"
                        : `/?country=${country.Id}`
                    }
                  >
                    {country.Name}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
      <div style={{ marginTop: 80 }}>
        {blogs.map((blog) => {
          var country = countries.find((c) => c.Id === blog.CountryId);
          return (
            <section
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: 768,
                marginBottom: "6rem",
              }}
              key={blog._id}
            >
              <h2 style={{ display: "flex", justifyContent: "center" }}>
                <a
                  style={{ display: "flex", flexDirection: "column" }}
                  href={`/?blogId=${blog.Id}`}
                >
                  <span style={{ color: "darkslategrey" }}>{blog.Name}</span>
                  {blog.City && (
                    <span style={{ fontSize: 22, marginTop: 8, color: "grey" }}>
                      {blog.City}
                    </span>
                  )}
                </a>
              </h2>
              <div style={{ position: "relative" }}>
                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    marginBottom: "1rem",
                  }}
                >
                  {mainImages
                    .filter((image) => image.indexOf(`/Blog_${blog.Id}/`) > -1)
                    .map((image) => (
                      <li key={image}>
                        <img
                          style={{
                            maxHeight: 245,
                            objectFit: "cover",
                            margin: "3px 4px",
                          }}
                          src={image}
                          alt={blog.Name}
                        />
                      </li>
                    ))}
                </ul>
                <a
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    bottom: 32,
                    right: "calc(50% - 60px)",
                    width: 120,
                    backgroundColor: "white",
                    border: "none",

                    padding: "0.5rem  0.75rem",
                    textTransform: "uppercase",
                    fontSize: "0.75rem",
                    fontWeight: 500,
                    letterSpacing: "0.25rem",
                    color: "#000",
                    cursor: "pointer",
                  }}
                  href={`/?blogId=${blog.Id}&photos=all`}
                >
                  Pictures
                </a>
              </div>
              <label
                style={{
                  color: "gray",
                  marginBottom: "1rem",
                  fontSize: "0.8rem",
                }}
              >
                {country.Name} — published at{" "}
                {Intl.DateTimeFormat("fr").format(new Date(blog.CreationDate))}
              </label>
              {!blogId && (
                <p style={{ marginBottom: "2rem" }}>
                  {blog.Description.split(" ").slice(0, 60).join(" ")}...
                </p>
              )}
              {blogId && (
                <div>
                  {blog.Description.split("\r\n").map((d, i) =>
                    !!d ? <p key={i}>{d}</p> : ""
                  )}
                </div>
              )}

              {!blogId && (
                <a
                  style={{
                    textTransform: "uppercase",
                    letterSpacing: "0.25rem",
                    fontWeight: 600,
                    textDecoration: "underline",
                  }}
                  href={`/?blogId=${blog.Id}`}
                >
                  View more
                </a>
              )}
            </section>
          );
        })}
      </div>
    </>
  );
}

export default Home;
