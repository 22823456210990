import { Outlet, useSearchParams } from "react-router-dom";

function AppLayout() {
  const [searchParams] = useSearchParams();
  const blogId = searchParams.get("blogId");
  const photos = searchParams.get("photos");

  return (
    <main
      style={{
        //border: "1px solid red",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      {blogId && !photos && (
        <a
          style={{
            padding: "0.5rem  0.75rem",
            textTransform: "uppercase",
            fontSize: "0.75rem",
            fontWeight: 500,
            letterSpacing: "0.25rem",
            position: "fixed",
            top: 100,
            right: 0,
            border: "1px solid rgba(0, 0, 0, 0.08)",
            backgroundColor: "#000",
            color: "#FFF",
          }}
          href={`/?blogId=${blogId}&photos=all`}
        >
          Pictures
        </a>
      )}

      <button
        style={{
          padding: "0.5rem 0.75rem",
          textTransform: "uppercase",
          fontSize: "0.75rem",
          fontWeight: 500,
          letterSpacing: "0.25rem",
          position: "fixed",
          top: 100,
          left: 0,
          border: "1px solid rgba(0, 0, 0, 0.08)",
          backgroundColor: "#000",
          color: "#FFF",
        }}
        onClick={() => window.history.back()}
      >
        Back
      </button>
      <div
        style={{
          //border: "1px solid yellow",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: 768,
          minWidth: "100%",
        }}
      >
        <header
          style={{
            //border: "1px solid blue",
            padding: "2rem 0",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "fixed",
            maxWidth: "inherit",
            backgroundColor: "#FFF",
            borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
            zIndex: 2,
          }}
        >
          <span
            style={{
              textTransform: "uppercase",
              letterSpacing: "0.2rem",
              fontWeight: 600,
              paddingLeft: "1.5rem",
            }}
          >
            Do Live Discover
          </span>
          <ul style={{ display: "flex" }}>
            <li>
              <a
                style={{
                  padding: "1rem 1rem",
                  textTransform: "uppercase",
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  letterSpacing: "0.25rem",
                }}
                href="/"
              >
                Home
              </a>
            </li>
            <li>
              <a
                style={{
                  padding: "1rem 1rem",
                  textTransform: "uppercase",
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  letterSpacing: "0.25rem",
                }}
                href="/?about=us"
              >
                About
              </a>
            </li>
          </ul>
        </header>
        <div
          style={{
            //border: "1px solid green",
            height: "100%",
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 81,
          }}
        >
          <Outlet />
        </div>
      </div>
    </main>
  );
}

export default AppLayout;
